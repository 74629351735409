import React, { useState, useEffect } from "react";
import { AgChartsReact } from "ag-charts-react";

import { createClient } from "@supabase/supabase-js";
import { Database } from "./database.types";

// Create a single supabase client for interacting with your database
const supabase = createClient<Database>(
  "https://nwexfqeyazbsowdxsmmj.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im53ZXhmcWV5YXpic293ZHhzbW1qIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTIyNDE0NzEsImV4cCI6MjAyNzgxNzQ3MX0.zU3e6VzNV3Wd_7fyfuYP6j4MhdXWMI5NunRKfLRuO5o"
);

export const App = () => {
  const [data, setData] = useState<any>();

  const fetchData = async () => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const formattedDate = thirtyDaysAgo.toISOString().split("T")[0];

    let { data: rankingData, error: rankingError } = await supabase
      .from("ranking")
      .select("*")
      .gte("date", formattedDate);

    if (rankingError) {
      throw rankingError;
    }

    if (!rankingData) {
      throw new Error("Could not get data");
    }

    let { data: personData, error: personError } = await supabase
      .from("person")
      .select("*")
      .in(
        "id",
        rankingData.map((item) => item.personId)
      );

    if (personError) {
      throw personError;
    }

    if (!personData) {
      throw new Error("Could not get data");
    }

    if (rankingData.length === 0) {
      setData({ chartData: [], seriesData: [] });
      return;
    }

    // Get the earliest date from the ranking data
    const earliestDate = rankingData.reduce((earliest, current) => {
      if (current.date < earliest) {
        return current.date;
      }

      return earliest;
    }, rankingData[0].date);

    // Get the latest date from the ranking data
    const latestDate = rankingData.reduce((latest, current) => {
      if (current.date > latest) {
        return current.date;
      }

      return latest;
    }, rankingData[0].date);

    // Create an array of dates starting with the earliest date and ending with the latest date
    const start = new Date(earliestDate);
    const end = new Date(latestDate);
    let dateArray: string[] = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      dateArray.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    function formatDateToUTC(date: Date) {
      const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11
      const day = date.getUTCDate();

      // Pad the month and day with a leading zero if they are less than 10
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;

      return `${formattedMonth}/${formattedDay}`;
    }

    // Loop over each date and add the ranking data to the data
    const chartData: any[] = [];
    dateArray.forEach((date) => {
      const d: any = {
        date: formatDateToUTC(new Date(date)),
        zero: 0,
      };

      // Nested loop, sorry, no time to optimize
      if (personData) {
        for (const person of personData) {
          const foundRanking = rankingData?.find(
            (r) => r.date === date && r.personId === person.id
          )?.ranking;
          if (foundRanking !== undefined) {
            d[`person-${person.id}`] = foundRanking;
          }
          //   const lastObject = chartData[chartData.length - 1];
          //   let lastRanking: number | undefined = undefined;
          //   if (lastObject) {
          //     lastRanking = lastObject[`person-${person.id}`];
          //   }
        }
      }

      chartData.push(d);
    });

    // Create the series data
    const computedSeriesData: any[] = personData.map((person) => {
      return {
        type: "line",
        xKey: "date",
        yKey: `person-${person.id}`,
        yName: person.name,
        connectMissingData: true,
        strokeWidth: 3, // Thicker line for the series
        marker: {
          size: 8, // Larger point size for visibility
          strokeWidth: 4,
          enabled: false,
        },
        curve: "smooth",
      };
    });

    // Add a series for the white line at zero
    computedSeriesData.push({
      type: "line",
      xKey: "date",
      yKey: "zero",
      yName: "Zero",
      connectMissingData: true,
      strokeWidth: 4, // Thicker line for the series
      marker: {
        size: 0, // Larger point size for visibility
        strokeWidth: 4,
      },
      stroke: "#ffffff", // White line color
      showInLegend: false,
      tooltip: {
        enabled: false,
      },
    });

    setData({
      chartData: chartData,
      seriesData: computedSeriesData,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    supabase
      .channel("room1")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "ranking" },
        (payload) => {
          fetchData();
        }
      )
      .subscribe();
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: "100vh", backgroundColor: "#2a2a2a" }}>
      <AgChartsReact
        key={data}
        options={{
          background: {
            fill: "#2a2a2a", // Dark background color
          },
          title: {
            text: "How Back Are We?",
            fontSize: 24, // Larger title font for visibility
            fontWeight: "bold",
            color: "#ffffff", // White color for the title text
          },
          data: data.chartData,
          series: data.seriesData,
          legend: {
            item: {
              label: {
                fontSize: 16, // Larger font for legend items
                color: "#ffffff", // White color for the legend text
                fontFamily: 'Consolas, "Courier New", monospace', // Monospaced font for the legend
              },
            },
          },
          axes: [
            {
              type: "category",
              position: "bottom",
              gridLine: {
                enabled: false,
              },
              title: {
                enabled: false,
                text: "Date",
                color: "#ffffff", // White color for axis title
              },
              label: {
                color: "#ffffff", // White color for axis labels
                fontSize: 16, // Larger font for axis labels
                fontFamily: 'Consolas, "Courier New", monospace', // Monospaced font for the axes
              },
            },
            {
              type: "number",
              position: "left",
              title: {
                enabled: false,
              },
              gridLine: {
                enabled: false,
              },
              min: -5,
              max: 5,
              nice: false,
              tick: {
                interval: 1,
                color: "#2a2a2a",
              },
              label: {
                formatter: (params) => {
                  if (params.value === "5") {
                    return "So Back";
                  } else if (params.value === "-5") {
                    return "So Over";
                  } else if (params.value === "0") {
                    return "Chilling";
                  }
                  return ""; // For other values, return the value itself
                },
                color: "#ffffff", // White color for axis labels
                fontSize: 16, // Larger font for axis labels
                fontFamily: 'Consolas, "Courier New", monospace', // Monospaced font for the axes
              },
            },
          ],
        }}
      />
    </div>
  );
};
