import { createClient } from "@supabase/supabase-js";
import { Database } from "./database.types";

const supabase = createClient<Database>(
  "https://nwexfqeyazbsowdxsmmj.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im53ZXhmcWV5YXpic293ZHhzbW1qIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTIyNDE0NzEsImV4cCI6MjAyNzgxNzQ3MX0.zU3e6VzNV3Wd_7fyfuYP6j4MhdXWMI5NunRKfLRuO5o"
);

export const AddPerson = () => {
  const handleSubmit = async (data: any) => {
    if (data.name === "") {
      alert("Please add a name");
      return;
    }

    const { error: insertError } = await supabase.from("person").insert({
      name: data.name,
    });

    if (insertError) {
      console.error("Error inserting new ranking:", insertError);
      return;
    }

    window.alert("Added!");
  };

  return (
    <div>
      <p>Add a person</p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const formProps = Object.fromEntries(formData);
          await handleSubmit(formProps); // Assuming handleSubmit is the function where you want to use the form data
        }}
      >
        <label htmlFor="name">Name </label>
        <input required type="text" id="name" name="name" />
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};
