import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Update } from "./Update";
import { AddPerson } from "./AddPerson";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/update",
    element: <Update />,
  },
  {
    path: "/addperson",
    element: <AddPerson />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
