import { createClient } from "@supabase/supabase-js";
import { useState, useEffect } from "react";
import { Database } from "./database.types";

const supabase = createClient<Database>(
  "https://nwexfqeyazbsowdxsmmj.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im53ZXhmcWV5YXpic293ZHhzbW1qIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTIyNDE0NzEsImV4cCI6MjAyNzgxNzQ3MX0.zU3e6VzNV3Wd_7fyfuYP6j4MhdXWMI5NunRKfLRuO5o"
);

export const Update = () => {
  const [people, setPeople] = useState<Array<{ id: number; name: string }>>([]);

  const getData = async () => {
    let { data: personData, error } = await supabase
      .from("person")
      .select("*")
      .order("name");
    if (error) {
      throw error;
    }

    if (!personData) {
      throw new Error("Could not get data");
    }

    setPeople(personData.map((p) => ({ id: p.id, name: p.name })));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (data: any) => {
    if (data.person === "-1") {
      alert("Please select a person");
      return;
    }

    if (data.ranking < -5 || data.ranking > 5) {
      alert("Ranking must be between -5 and 5");
      return;
    }

    if (data.date === "") {
      alert("Please select a date");
      return;
    }

    // Make sure the date is in not in the future
    if (new Date(data.date) > new Date()) {
      alert("Date cannot be in the future");
      return;
    }

    // Check if the person has a ranking for that date
    // If they do, update it
    // If they don't, create a new ranking
    let { data: existingRanking, error: fetchError } = await supabase
      .from("ranking")
      .select("*")
      .eq("personId", Number(data.person))
      .eq("date", data.date)
      .maybeSingle();

    if (fetchError) {
      console.error("Error fetching existing ranking:", fetchError);
      return;
    }

    if (existingRanking) {
      const { error: updateError } = await supabase
        .from("ranking")
        .update({ ranking: data.ranking })
        .match({ id: existingRanking.id });

      if (updateError) {
        console.error("Error updating ranking:", updateError);
        return;
      }

      window.location.href = "/";
    } else {
      const { error: insertError } = await supabase.from("ranking").insert({
        personId: data.person,
        date: data.date,
        ranking: data.ranking,
      });

      if (insertError) {
        console.error("Error inserting new ranking:", insertError);
        return;
      }

      window.location.href = "/";
    }
  };

  return (
    <div>
      <p>Update how back you are</p>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const formProps = Object.fromEntries(formData);
          await handleSubmit(formProps); // Assuming handleSubmit is the function where you want to use the form data
        }}
      >
        <label htmlFor="person-select">Choose a person: </label>
        <select id="person-select" required name="person">
          <option value={-1}>Choose One</option>
          {people.map((person) => (
            <option key={person.id} value={person.id}>
              {person.name}
            </option>
          ))}
        </select>
        <br />
        <label htmlFor="date-select">Choose a date: </label>
        <input
          required
          max={
            new Date(
              new Date().toLocaleString("en-US", {
                timeZone: "America/New_York",
              })
            )
              .toISOString()
              .split("T")[0]
          }
          type="date"
          id="date-select"
          name="date"
          defaultValue={new Date().toISOString().split("T")[0]}
        />
        <br />
        <label htmlFor="ranking">Ranking (-5 to 5): </label>
        <input
          required
          type="number"
          id="ranking"
          name="ranking"
          min="-5"
          max="5"
        />
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};
